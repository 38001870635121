<template>
  <div class="contentbox">
    <div class="bankbox">
      <img src="../../assets/images/new/back-icon.png" alt="" @click="bankgo" />
      <p class="bankname">分享奖励</p>
    </div>
    <div class="typebox">
      <div
        class="typeitem point"
        :class="{ actype: actypeindex === item.id }"
        @click="typechange(item.id)"
        v-for="item in searchList"
        :key="item.id">
        {{ item.name }}
      </div>
    </div>
    <div class="center">
      <div class="infobox" v-if="actypeindex == 2">
        <div class="infobox" v-if="this.user.anchor == 0">
          <div class="infoitem">
            <div class="infotext">
              <p class="text">我的下级人数</p>
              <p class="numbox">{{ share_user_count }}</p>
            </div>
          </div>
          <div class="infoitem">
            <div class="infotext">
              <p class="text">
                下级充值奖励
                <span class="numbox" style="vertical-align: middle">3%</span>
              </p>
              <p
                class="numbox"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <img
                  style="width: 0.12rem; margin-right: 0.1rem"
                  src="../../assets/images/about/user/huobi.png"
                  alt="" />{{ share_money }}
              </p>
            </div>
          </div>
        </div>
        <!-- 主播 -->
        <div class="infobox" v-else>
          <div class="infoitem">
            <div class="infotext">
              <p class="text">累计推广人数</p>
              <p class="numbox">{{ userdata.share_num }}</p>
            </div>
          </div>
          <div class="infoitem">
            <div class="infotext">
              <p class="text">累计充值人数</p>
              <p class="numbox">{{ userdata?.change_num }}</p>
            </div>
          </div>
          <div class="infoitem">
            <div class="infotext">
              <p class="text">累计充值金额</p>
              <p
                class="numbox"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <img
                  style="width: 0.12rem; margin-right: 0.1rem"
                  src="../../assets/images/about/user/huobi.png"
                  alt="" />{{ userdata?.change_money }}
              </p>
            </div>
          </div>
          <div class="infoitem">
            <div class="infotext">
              <p class="text">累计领取佣金</p>
              <p
                class="numbox"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <img
                  style="width: 0.12rem; margin-right: 0.1rem"
                  src="../../assets/images/about/user/huobi.png"
                  alt="" />{{ userdata?.receive_money }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="actypeindex == 1">
        <div class="title">推广链接</div>
        <div class="cord-box">
          <div class="info-box">
            <span class="span">{{ Url }}</span>
            <div @click="textCopy(Url)" class="btn point">复制</div>
          </div>
          <!-- <div class="btn btn-cord">二维码</div> -->
        </div>
        <div class="title">推广码</div>
        <div class="cord-box">
          <div class="info-box">
            <span class="span">{{ UrlCode }}</span>
            <div @click="textCopy(UrlCode)" class="btn point">复制</div>
          </div>
        </div>
      </div>

      <div v-if="actypeindex == 1">
        <div class="title">奖励说明</div>
        <div class="tipstext" v-if="this.user.anchor == 1">
          用户的下级通过充值T币时，用户可获得额外奖励。用户额外奖励 =
          下级充值T币额度的3%（奖励即充即送！）
        </div>
        <div class="tipstext" v-else>
          用户的下级通过充值T币时，用户可获得额外奖励。用户额外奖励 =
          下级充值T币额度的3%（奖励即充即送！）
        </div>
      </div>
      <div v-if="actypeindex == 2">
        <div class="title" v-if="this.user.anchor == 1">主播结算详情</div>
        <div class="title" v-else>充值奖金记录</div>
        <!-- 主播 -->
        <div class="tablelist" v-if="this.user.anchor == 1">
          <div class="nulldata" v-if="Anchordata.length == 0">
            <div class="imgbox">
              <img src="@/assets/images/newuser/mb-table-nulldata.png" alt="" />
              <div class="nulltext">暂无内容</div>
            </div>
          </div>
          <div
            class="user1box"
            v-for="(item, index) in Anchordata"
            :key="index">
            <!-- 主播展示列表 -->
            <div class="listitem">
              <div class="topbox">
                <div class="titem">
                  <div class="boxcontent">
                    <div class="text">充值次数</div>
                    <div class="nums">
                      {{ item.change_num }}
                    </div>
                  </div>
                </div>
                <div class="titem">
                  <div class="boxcontent">
                    <div class="text">充值金额</div>
                    <div class="nums">
                      <img
                        style="width: 0.12rem; margin-right: 0.06rem"
                        src="@/assets/images/about/user/huobi.png"
                        alt="" />
                      {{ item.change_money }}
                    </div>
                  </div>
                </div>
                <div class="titem">
                  <div class="boxcontent">
                    <div class="text">我的佣金</div>
                    <div class="nums">
                      <img
                        style="width: 0.12rem; margin-right: 0.06rem"
                        src="@/assets/images/about/user/huobi.png"
                        alt="" />
                      {{ item.commission_money }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="bootombox">
                <div class="box2">结算日： {{ item.date }}</div>
                <div class="box2">佣金比例：{{ item.commission_rate }}%</div>
                <div class="statebox" v-if="item.status == 3">
                  <div class="icon"></div>
                  <div class="statetext">已领取</div>
                </div>
                <div class="statebox" v-if="item.status == 2">
                  <div class="icon i2"></div>
                  <div class="statetext t2">未领取</div>
                </div>
                <div class="statebox" v-if="item.status == 1">
                  <div class="icon i1"></div>
                  <div class="statetext t1">未结算</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Pagination
              v-show="Pagetotal > 0"
              :total="Pagetotal"
              :page.sync="page"
              :limit.sync="pagesize"
              @pagination="handleCurrentChange" />
          </div>
        </div>
        <!-- 用户 -->
        <div class="tablelist" v-else>
          <div class="nulldata" v-if="userlistdata.length == 0">
            <div class="imgbox">
              <img src="@/assets/images/newuser/mb-table-nulldata.png" alt="" />
              <div class="nulltext">暂无内容</div>
            </div>
          </div>
          <div
            class="user1box"
            v-for="(item, index) in userlistdata"
            :key="index">
            <!-- 用户展示列表 -->
            <div class="listitem">
              <div class="topbox">
                <div class="box1">
                  <div class="boxcontent">
                    <div class="text">充值金额</div>
                    <div class="nums">
                      <img
                        style="width: 0.12rem; margin-right: 0.06rem"
                        src="@/assets/images/about/user/huobi.png"
                        alt="" />
                      {{ item?.charge_bean }}
                    </div>
                  </div>
                </div>
                <div class="line2"></div>
                <div class="box1">
                  <div class="boxcontent">
                    <div class="text">推广奖励</div>
                    <div class="nums">
                      <img
                        style="width: 0.12rem; margin-right: 0.06rem"
                        src="@/assets/images/about/user/huobi.png"
                        alt="" />
                      {{ item?.bean }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="bootombox">
                <div class="box2">{{ item?.next_user?.name }}</div>
                <div class="box2">{{ item?.created_at }}</div>
              </div>
            </div>
          </div>
          <div>
            <Pagination
              v-show="Pagetotal > 0"
              :total="Pagetotal"
              :page.sync="page"
              :limit.sync="pagesize"
              @pagination="RecentChange" />
          </div>
        </div>

        <!-- <newtable
          :tableRecordList="tableRecordList1"
          :tableData="tableData"
          @pageChange="RecentChange"
        /> -->
      </div>
      <!-- <div v-if="actypeindex == 3">
        <div class="title">下级详情</div>
        <newtable
          :tableRecordList="tableRecordList2"
          :tableData="downData"
          @pageChange="downChange"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import { tableRecordList1, tableRecordList2 } from "./index.js";
import {
  getawards_recordsApi,
  getlevel_detailApi,
  getCommissionApi,
} from "@/network/api.js";
import { mapState } from "vuex";
import newtable from "../../components/newtable/index.vue";
export default {
  data() {
    return {
      actypeindex: 1,
      tableRecordList1,
      tableRecordList2,
      // tableData: {
      //   total: 0,
      //   pageSize: 0,
      //   list: [],
      // },
      downData: {
        total: 0,
        pageSize: 0,
        list: [],
      },
      userdata: {},
      Anchordata: [], // 主播数据
      page: 1,
      pagesize: 4,
      Pagetotal: 0,

      userlistdata: [],
    };
  },
  components: {
    newtable,
  },
  created() {
    if (this.user.anchor == 0) {
      this.getdata();
    } else {
      this.getAnchordata();
      this.getuserdata();
    }
  },

  computed: {
    ...mapState(["user"]),
    searchList() {
      let array = [
        {
          name: "奖励说明",
          id: 1,
        },
        {
          name: "近期奖励",
          id: 2,
        },
      ];
      /**
    *   if (this.user.anchor == 1) {
        array = [
          {
            name: "奖励说明",
            id: 1,
          },
          {
            name: "近期奖励",
            id: 2,
          },
          {
            name: "下级详情",
            id: 3,
          },
        ];
      } */
      return array;
    },
    Url() {
      return `${location.origin}/Home?IvtCode=${this.user.invite_code}`;
    },
    UrlCode() {
      return `${this.user.invite_code}`;
    },
    share_money() {
      return `${this.user.promotion_total}`;
    },
    share_user_count() {
      return `${this.user.share_user_count}`;
    },
  },
  methods: {
    handleCurrentChange(v) {
      this.page = v.page;
      this.getAnchordata();
    },
    getuserdata() {
      let data = JSON.parse(localStorage.getItem("boxUserInfo"));
      this.userdata = data.user_data;
    },
    // 获取主播分享数据
    getAnchordata() {
      let params = {
        page: this.page,
        pagesize: this.pagesize,
      };
      getCommissionApi(params).then((res) => {
        this.Pagetotal = res.data.data.total;
        this.Anchordata = res.data.data.data;
        // this.Anchordata =[]
      });
    },
    bankgo() {
      this.$router.back();
    },
    // //下级分页
    // downChange(v) {
    //   this.getdowndata(v.page);
    // },
    // getdowndata(num) {
    //   getlevel_detailApi(num).then((res) => {
    //     let data = res.data.data.data;
    //     if (data.length > 0) {
    //       this.downData = {
    //         total: res.data.data.total,
    //         pageSize: res.data.data.per_page,
    //         list: data.map((item) => {
    //           return {
    //             name: item.name,
    //             total_recharge: item.total_recharge,
    //             total_record: item.total_record,
    //           };
    //         }),
    //       };
    //     }
    //   });
    // },
    // 近期奖励分页
    RecentChange(v) {
      this.page = v.page;
      this.getdata();
    },
    // 近期奖励数据获取
    getdata() {
      let params = {
        page: this.page,
        pagesize: this.pagesize,
      };
      getawards_recordsApi(params).then((res) => {
        this.Pagetotal = res.data.data.total;
        this.userlistdata = res.data.data.data;

        // this.tableData = {
        //   total: res.data.data.total,
        //   pageSize: res.data.data.per_page,
        //   list: data.map((item) => {
        //     return {
        //       time: item.created_at,
        //       name: item.next_user.name,
        //       charge_bean: item.charge_bean,
        //       price: item.bean,
        //     };
        //   }),
        // };
      });
    },
    typechange(v) {
      this.actypeindex = v;
      if (v == 2) {
        if (this.user.anchor == 0) {
          this.getdata();
        } else {
          this.getAnchordata();
        }
      }
    },
    async textCopy(t) {
      let textArea = document.createElement("textarea");
      textArea.value = t;
      textArea.style.position = "absolute";
      textArea.style.opacity = 0;
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      let success = false;
      try {
        success = document.execCommand("copy");
      } catch (err) {
        success = false;
      }
      textArea.remove();
      if (success) {
        this.$message.success("复制成功！");
      } else {
        this.$message.error("复制失败，请手动复制！");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contentbox {
  width: 100%;
  position: relative;
  .bankbox {
    width: 3.35rem;
    margin: 0 auto;
    height: 0.44rem;
    display: flex;
    align-items: center;
    background: #020715;
    text-align: center;
    position: relative;
    img {
      position: absolute;
      width: 0.25rem;
      height: 0.14rem;
    }
    .bankname {
      width: 100%;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.16rem;
      color: #ffffff;
    }
  }
  .typebox {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.1rem;
    .typeitem {
      padding: 0.1rem;
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #636363;
    }
    .actype {
      color: #c5bbed;
    }
  }
  .center {
    width: 3.35rem;
    margin: 0 auto;
    .infobox {
      display: flex;
      width: 3.35rem;
      justify-content: space-between;
      flex-wrap: wrap;
      .infoitem {
        width: 1.63rem;
        height: 0.8rem;
        background: #101022;
        border: 0.01rem solid #201e43;
        border-radius: 0.06rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-top: 0.09rem;
        .infotext {
          text-align: center;
          .text {
            margin-bottom: 0.2rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 0.12rem;
            color: #ffffff;
          }
        }
        .numbox {
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #efa900;
        }
      }
    }
    .title {
      margin: 0.3rem 0 0.18rem 0;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.12rem;
      color: #ffffff;
    }
    .nulldata {
      width: 100%;
      min-height: 2rem;
      max-height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .imgbox {
        width: 0.8rem;
        height: 0.8rem;
        img {
          width: 100%;
        }
        .nulltext {
          text-align: center;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #c5bbed;
        }
      }
    }
    .user1box {
      width: 3.35rem;
      margin: 0 auto 0.16rem;
      .listitem {
        width: 100%;
        height: 0.9rem;
        background: #08081e;
        border: 0.01rem solid #5d5d9b;
        .topbox {
          width: 100%;
          height: 0.59rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .box1 {
            width: 49.5%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .boxcontent {
            text-align: center;
            .text {
              margin-bottom: 0.1rem;
              font-weight: 400;
              font-size: 0.12rem;
              color: #ffffff;
            }
            .nums {
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 500;
              font-size: 0.12rem;
              color: #efa900;
            }
          }
          .titem {
            width: 33.5%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .line2 {
            height: 0.4rem;
            border: 0.01rem solid;
            border-image: linear-gradient(
                180deg,
                rgba(43, 41, 87, 0),
                rgba(52, 50, 105, 1),
                rgba(52, 50, 105, 0)
              )
              1 1;
          }
        }
        .bootombox {
          width: 100%;
          height: 0.29rem;
          background: #0e0e2d;
          display: flex;
          justify-content: space-between;
          padding: 0 0.12rem;
          align-items: center;
          font-weight: 400;
          font-size: 0.1rem;
          color: #dfdbff;
          .statebox {
            display: flex;
            align-items: center;
            .icon {
              width: 0.04rem;
              height: 0.04rem;
              border-radius: 50%;
              background: #dfdbff;
              margin-right: 0.03rem;
            }
            .i2 {
              background: #49d3ff;
            }
            .i1 {
              background: #ff3c48;
            }
            .t2 {
              background: #49d3ff;
            }
            .t1 {
              background: #ff3c48;
            }
          }
        }
      }
    }
    .cord-box {
      width: 100%;
      .info-box {
        display: flex;
        .span {
          width: 2.67rem;
          height: 0.26rem;
          background: #08080f;
          border: 0.01rem solid #2f2f5e;
          line-height: 0.26rem;
          font-size: 0.1rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #8080b5;
          padding-left: 0.1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .btn {
          width: 0.64rem;
          height: 0.26rem;
          background: linear-gradient(90deg, #242448 0%, #42426d 100%);
          border: 0.01rem solid #8686db;
          text-align: center;
          line-height: 0.26rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-left: 0.14rem;
        }
      }
    }
    .tipstext {
      font-size: 0.1rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #8080b5;
    }
  }
}
</style>

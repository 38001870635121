// 近期奖励
export const tableRecordList1 = [
    {
        title: "时间",
        width: "30%",
        index: "time",
        center: "center",
    },
    {
        title: "下级名称",
        width: "30%",
        index: "name",
        center: "center",
    },
    {
        title: "充值金额",
        width: "20%",
        index: "charge_bean",
        center: "center",
    },
    {
        title: "奖励金额",
        width: "20%",
        index: "price",
        center: "center",
    }
];
// 下级详情
export const tableRecordList2 = [
    {
        title: "用户ID",
        width: "30%",
        index: "name",
        center: "center",
    },
    {
        title: "充值",
        width: "30%",
        index: "total_recharge",
        center: "center",
    },
    {
        title: "消耗",
        width: "30%",
        index: "total_record",
        center: "center",
    }
];
// 团队消耗
export const tableRecordList3 = [
    {
        title: "用户ID",
        width: "30%",
        index: "time",
        center: "center",
    },
    {
        title: "下级消耗奖励",
        width: "30%",
        index: "type",
        center: "center",
    },
    {
        title: "下级分享奖励",
        width: "30%",
        index: "share",
        center: "center",
    },
    {
        title: "佣金总额",
        width: "30%",
        index: "price",
        center: "center",
    }
];
export const tableData = {
    total: 0,
    list: [
        {
            time: "07-04:11:11:11",
            type: 1,
            price: 2.33,
        },
        {
            time: "07-04:11:11:11",
            type: 1,
            price: 2.33,
        },
        {
            time: "07-04:11:11:11",
            type: 1,
            price: 2.33,
        },
        {
            time: "07-04:11:11:11",
            type: 1,
            price: 2.33,
        },
        {
            time: "07-04:11:11:11",
            type: 1,
            price: 2.33,
        },
        {
            time: "07-04:11:11:11",
            type: 1,
            price: 2.33,
        },
    ],
};
export const dropList = [
    {
        name: '选择类型',
        id: 0,
    },
    {
        name: '游戏',
        id: 1
    }
]
export const dropList1 = [
    {
        name: '选择时间',
        id: 0,
    },
    {
        name: '游戏',
        id: 1
    }
]